'use client'

import React from 'react'
import { useUser } from '@clerk/nextjs'
import { StatsigProvider as OGStatsigProvider } from 'statsig-react'
import * as z from 'zod'

import type { StatsigOptions, StatsigUser } from 'statsig-react'
import { create } from 'lodash'

const Environment = z.object({
  NEXT_PUBLIC_STATSIG_CLIENT_KEY: z.string().nonempty(),
  NEXT_PUBLIC_VERCEL_ENV: z
    .union([
      z.literal('production'),
      z.literal('preview'),
      z.literal('development'),
    ])
    .default('development'),
})

const environment = Environment.parse({
  NEXT_PUBLIC_STATSIG_CLIENT_KEY: process.env.NEXT_PUBLIC_STATSIG_CLIENT_KEY,
  NEXT_PUBLIC_VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV,
})

export type StatsigProviderProps = {
  children: React.ReactNode
}

export function StatsigProvider({ children }: StatsigProviderProps) {
  const options = {
    environment: {
      tier:
        environment.NEXT_PUBLIC_VERCEL_ENV === 'preview'
          ? 'staging'
          : environment.NEXT_PUBLIC_VERCEL_ENV === 'production'
          ? 'production'
          : 'development',
    },
  }
  const sdkKey = environment.NEXT_PUBLIC_STATSIG_CLIENT_KEY

  const { user } = useUser()
  const statsigUser: StatsigUser = {
    userID: user?.id,
    email: user?.primaryEmailAddress?.emailAddress,
  }

  return (
    <OGStatsigProvider sdkKey={sdkKey} options={options} user={statsigUser}>
      {children}
    </OGStatsigProvider>
  )
}
