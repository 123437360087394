'use client'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import React, { useState, useRef } from 'react'
import Image from 'next/image'
import { UserContext } from '../user-context'
import { motion, AnimatePresence } from 'framer-motion'
import { ACCEPTED_FILE_TYPES } from '@/app/constants'

const FileUploadWidget = () => {
  const [open, setOpen] = useState(true)

  const {
    filesUploading,
    clearFilesUploading,
    setFilesUploaded,
    collection,
    orgId,
  } = React.useContext(UserContext)

  const fileInput = useRef(null)

  const onFileChange = async (event: any) => {
    const file = event.target.files[0]
    const sizeInMB = file.size / (1024 * 1024)
    if (sizeInMB > 50) {
      alert('File size exceeds 50 MB limit')
    } else {
      // proceed with the upload
      // @ts-ignore
      setFilesToUpload((files: unknown) => [...files, file])
    }
  }

  const getFilesWithStatus = (status: string) =>
    filesUploading.filter(
      (file: { progress: number; status: string }) => file.status === status,
    )
  const activeFiles = getFilesWithStatus('in-progress').length
  const completedFiles = getFilesWithStatus('complete').map((file: any) => {
    return `${orgId}/${collection}/${file.name}`
  })

  React.useEffect(() => {
    setFilesUploaded(completedFiles)
  }, [filesUploading])

  return (
    <>
      {filesUploading && filesUploading.length ? (
        <AnimatePresence>
          <motion.div
            className="fixed w-96 bottom-4 right-20 bg-white border border-gray rounded-md shadow-md z-50"
            id="file-upload-widget"
            initial={{
              opacity: 0,
              y: '50%',
            }}
            animate={{
              opacity: 1,
              y: '0%',
            }}
            transition={{
              ease: 'easeOut',
              duration: 0.1,
            }}
            exit={{
              opacity: 0,
              y: '50%',
              transition: {
                duration: 0.1,
              },
            }}
          >
            <input
              type="file"
              style={{ display: 'none' }}
              ref={fileInput}
              onChange={onFileChange}
              accept={ACCEPTED_FILE_TYPES.join(',')}
            />
            <div
              id="file-upload-header"
              className=" w-full bg-gray-200 flex flex-row justify-between p-2 text-xs font-medium items-center"
            >
              <div
                id="file-upload-title"
                className="text-xs leading-none	flex flex-rows items-center"
              >
                <XMarkIcon
                  onClick={clearFilesUploading}
                  className="text-black h-4 font-medium cursor-pointer mr-2"
                />
                {activeFiles === 0 &&
                  `${
                    filesUploading.filter(
                      (file: { status: string }) => file.status === 'complete',
                    ).length
                  } file(s) uploaded`}
                {activeFiles > 0 &&
                  `${activeFiles} of ${filesUploading.length} files uploading`}
              </div>
              <div className="flex flex-rows">
                {open ? (
                  <ChevronDownIcon
                    onClick={() => setOpen((x) => !x)}
                    className="text-black h-4 font-medium cursor-pointer"
                  />
                ) : (
                  <ChevronUpIcon
                    onClick={() => setOpen((x) => !x)}
                    className="text-black h-4 font-medium cursor-pointer"
                  />
                )}
              </div>
            </div>
            {open && (
              <div id="file-upload-file-container" className="p-2 w-full max-h-96 overflow-auto">
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileInput}
                  onChange={onFileChange}
                  accept={ACCEPTED_FILE_TYPES.join(',')}
                />
                {filesUploading.map(
                  (file: {
                    name: string
                    progress: number | string
                    status: string
                  }) => {
                    return (
                      <div
                        key={file.name}
                        className="file-upload-card p-2 flex flex-row items-center w-full"
                      >
                        <Image
                          src="/icons/image-01.svg"
                          alt="file-type-icon"
                          width={18}
                          height={18}
                          className="mr-2 w-6"
                        />
                        <div className="flex flex-col w-full truncate">
                          <span className="file-name text-xs font-medium flex justify-between flex-row items-center">
                            <span className="truncate w-60">{file.name} </span>
                            {file.status === 'complete' && (
                              <span className="text-xs p-1 text-white rounded bg-green-500">
                                Complete
                              </span>
                            )}
                            {file.status === 'error' && (
                              <span className="text-xs p-1 text-white rounded bg-red-500">
                                Error
                              </span>
                            )}
                          </span>
                          <div className="file-loading-status-container w-full flex flex-row items-center pr-4">
                            {file.status === 'in-progress' && (
                              <>
                                <span className="file-loading-status text-xs mr-2">
                                  {file.progress}%
                                </span>
                                <div className="file-loading-status-bar bg-gray-200 rounded w-full h-2">
                                  <motion.div
                                    className="file-loading-status-bar-progress bg-black rounded h-full"
                                    initial={{ opacity: 0 }}
                                    whileInView={{ opacity: 1 }}
                                    transition={{
                                      ease: 'easeIn',
                                      duration: 0.2,
                                    }}
                                    animate={{ opacity: 1 }}
                                    style={{ width: `${file.progress}%` }}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  },
                )}
              </div>
            )}
          </motion.div>
        </AnimatePresence>
      ) : null}
    </>
  )
}

export default FileUploadWidget
