import(/* webpackMode: "eager" */ "/vercel/path1/app/components/FileUploadWidget.tsx");
import(/* webpackMode: "eager" */ "/vercel/path1/app/components/StatsigProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path1/app/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path1/app/user-context.tsx");
import(/* webpackMode: "eager" */ "/vercel/path1/lib/analytics/GoogleAnalytics.tsx");
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/@vercel/analytics/dist/react/index.js");
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js")