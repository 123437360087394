export const ACCEPTED_FILE_TYPES = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/xml',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'text/csv',
  'text/markdown',
  'text/plain',
  'text/tab-separated-values',
]

export const NAV_MENU_WIDTH_OPEN = 'w-[236px]'
export const NAV_MENU_WIDTH_CLOSED = 'w-[4px]'

export const NAV_MENU_POSITION_OPEN = 'left-0 top-0'
export const NAV_MENU_POSITION_CLOSED = '-left-[232px] top-[64px]'

export const NAV_MENU_HOVER_POSITION_OPEN =
  'left-0 bottom-0 top-[64px] border rounded-lg [&>nav]:h-full'
