'use client'
import React from 'react'
import { usePathname } from 'next/navigation'
import useSWR, { useSWRConfig } from 'swr'
import { useRef } from 'react'
import * as Sentry from '@sentry/react'
import { checkIfDocIdExists } from './actions/collections/collections'
import { Appv2 } from './dashboard/apps/v2/[id]/types'
import { useOrganization, useUser } from '@clerk/nextjs'
export const UserContext = React.createContext<any>({
  currentUrl: '',
  models: [],
})

export const UserContextProvider = ({ children }: any) => {
  const pathName = usePathname()
  const [orgId, setOrgId] = React.useState<string | null>(null)
  const { mutate: swrMutate } = useSWRConfig()
  const { isLoaded, isSignedIn } = useUser()
  const { organization } = useOrganization()

  React.useEffect(() => {
    swrMutate((key: string) => true, undefined, { revalidate: true })
  }, [orgId])

  const fetcher = (path: string) =>
    fetch(path).then((res) => {
      if (res.ok) {
        return res.json()
      }
      throw new Error('Network response was not ok.')
    })
  const {
    data: models,
    isLoading,
    mutate: fetchModels,
    error,
  } = useSWR(
    () =>
      !isLoaded ? null : isSignedIn && organization ? '/api/models' : null,
    fetcher,
  )

  const appsFetcher = (path: string) =>
    fetch(path, {
      method: 'GET',
    }).then(async (res) => {
      if (res.ok) {
        const { payload } = await res.json()
        return payload
      }
      throw new Error('Error fetching app flows')
    })
  const {
    data: apps,
    isLoading: loadingApps,
    mutate: fetchApps,
  } = useSWR(
    () => (!isLoaded ? null : isSignedIn && organization ? '/api/flow' : null),
    appsFetcher,
  )

  const [filesUploading, setFilesUploading] = React.useState<any[]>([])
  const [filesUploaded, setFilesUploaded] = React.useState<string[]>([])
  const [collection, setCollection] = React.useState<string | null>(null)
  const [clearChatConversation, setClearChatConversation] =
    React.useState(false)
  const fileInputRef = useRef<any>(null)

  const updateStatus = (
    file: File,
    progress: number,
    status: 'in-progress' | 'complete' | 'error',
  ) => {
    setFilesUploading((files) => {
      return files.map((stateFile) => {
        if (stateFile.name === file.name) {
          return {
            ...stateFile,
            progress,
            status,
          }
        }
        return stateFile
      })
    })
  }

  const triggerFileUploadIndexJob = async (
    collection: string | null,
    file_name: string,
  ) => {
    // post to endpoint
    if (!collection) {
      alert('no collection selected')
      return
    }
    const res = await fetch('/api/index-file', {
      method: 'POST',
      body: JSON.stringify({
        collectionID: collection,
        type: 's3_file',
        file_name,
        tags: [],
      }),
    })
    const data = await res.json()
    console.log('trigger indexing res!', data)
  }

  const handleSubmit = async (file: File) => {
    const filename = encodeURIComponent(file.name)
    const fileType = encodeURIComponent(file.type)

    const res = await fetch(
      `/api/signed-url?file=${filename}&fileType=${fileType}&collectionId=${collection}`,
    )

    if (!res.ok) {
      console.log('error getting signed url')
      Sentry.captureException(new Error('error getting signed url'))
      updateStatus(file, 0, 'error')
      return
    }

    const { url, fields } = await res.json()
    const formData = new FormData()

    Object.entries({ ...fields, file }).forEach(([key, value]) => {
      formData.append(key, value as string)
    })

    // log the formData
    // @ts-ignore
    for (var pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1])
    }

    // Create a new promise that wraps the XMLHttpRequest
    const upload = new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()

      xhr.open('POST', url, true)

      // Event listener for upload progress
      xhr.upload.onprogress = function (event) {
        updateStatus(
          file,
          Math.round((event.loaded / event.total) * 100),
          'in-progress',
        )

        if (event.lengthComputable) {
          const percentage = (event.loaded / event.total) * 100
          console.log(`Uploaded: ${percentage}%`)
        }
      }

      // Event listener for when the request is completed
      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 400) {
          console.log('SUCCESS!!')

          triggerFileUploadIndexJob(collection, file.name)
          updateStatus(file, 100, 'complete')

          // call to endpoint to begin indexing
          resolve(xhr.response)
        } else {
          updateStatus(file, 0, 'error')
          console.log('ERRORRR', xhr.statusText)
          reject(xhr.statusText)
        }
      }

      // Event listener for any errors
      xhr.onerror = function () {
        reject('Upload error.')
      }

      xhr.send(formData)
    })

    try {
      const res = await upload
      console.log('res', res)

      console.log('Uploaded successfully!')
    } catch (error) {
      console.error('Upload failed.', error)
    }
  }

  const onTriggerUpload = (collection: string) => {
    setCollection(collection)
    fileInputRef?.current?.click()
  }

  const onFileChange = async (event: any) => {
    if (!event.target.files) return

    const files = Array.from(event.target.files) as File[]

    if (!collection) {
      alert('no collection selected')
      return
    }

    for (const file of files) {
      const documentId = `${orgId}/${collection}/${file.name}`
      const duplicateDocId = await checkIfDocIdExists(collection, documentId)
      if (duplicateDocId) {
        const override = confirm(
          `Document with name "${file.name}" already exists. Rename the file and try again, or press OK to override existing document.`,
        )
        if (!override) {
          return
        }
      }

      const sizeInMB = file.size / (1024 * 1024)

      if (sizeInMB > 50) {
        alert('File size exceeds 50 MB limit')
      } else {
        // proceed with the upload
        setFilesUploading((prevFiles) => [
          ...prevFiles,
          { name: file.name, progress: 0 },
        ])
        handleSubmit(file) // Ensure this function is available in the current scope
      }
    }
  }

  const [app, setApp] = React.useState<Appv2 | null>(null)
  const [addingBlock, setAddingBlock] = React.useState<boolean>(false)

  return (
    <UserContext.Provider
      value={{
        currentPathName: pathName,
        models,
        fetchModels,
        isLoading,
        onTriggerUpload,
        fileInputRef,
        onFileChange,
        filesUploading,
        clearFilesUploading: () => {
          setFilesUploading([])
        },
        clearChatConversation,
        setClearChatConversation,
        orgId,
        setOrgId,
        filesUploaded,
        setFilesUploaded,
        collection,
        setCollection,
        apps,
        loadingApps,
        fetchApps,
        app,
        setApp,
        addingBlock,
        setAddingBlock,
      }}
    >
      <>{children}</>
    </UserContext.Provider>
  )
}
